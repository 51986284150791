import React from 'react';
import PropTypes from 'prop-types';
import { VerticalBarWrapper } from '../atoms/VerticalBarWrapper';
import { Select } from '../atoms/Select';

export const SectionSelect = ({ value, options, onChange }) => (
  <VerticalBarWrapper>
    <Select value={value} options={options} onChange={onChange} />
  </VerticalBarWrapper>
);

SectionSelect.propTypes = {
  value: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.any).isRequired,
  onChange: PropTypes.func.isRequired
};
