import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { t } from '../../../../domain/services/configService';
import { ContainerMain } from '../../atoms/ContainerMain';
import { CardGridLeftRightWithAdLegacy } from '../CardGridLeftRightWithAdLegacy';
import { SectionHeader } from '../../molecules/SectionHeader';
import { SectionSelect } from '../../molecules/SectionSelect';

export const ShowVideos = ({ videos, content }) => {
  const [season, setSeason] = useState('');
  const handleChange = e => {
    setSeason(e.target.value);
  };

  let videosToShow = [];
  if (season === 'clips') {
    videosToShow = videos.clips;
  } else if (season) {
    if (videos.seasons && videos.seasons[season]) {
      if (videos.seasons[season].episodes) {
        videosToShow.push(...videos.seasons[season].episodes);
      }
      if (videos.seasons[season].clips) {
        videosToShow.push(...videos.seasons[season].clips);
      }
    }
  } else {
    if (videos.seasons) {
      Object.keys(videos.seasons).forEach(seasonKey => {
        if (videos.seasons[seasonKey].episodes) {
          videosToShow.push(...videos.seasons[seasonKey].episodes);
        }
        if (videos.seasons[seasonKey].clips) {
          videosToShow.push(...videos.seasons[seasonKey].clips);
        }
      });
    }
    if (videos.clips) {
      videosToShow.push(...videos.clips);
    }
  }

  const cardItems = videosToShow.map(video => {
    const imageEntity = {
      mediaImage: {
        url: video.image_url,
        width: 1920,
        height: 1080
      }
    };
    const taxonomies = [{ name: video.tag, isPtt: true }];
    return {
      data: {
        type: 'video',
        url: video.url,
        title: video.title,
        imageEntity,
        videoDuration: video.duration,
        taxonomies
      }
    };
  });

  const selectOptions = [];
  if (videos.seasons) {
    Object.keys(videos.seasons).forEach(seasonId => {
      selectOptions.push({
        name: `${t('Season')} ${seasonId}`,
        value: seasonId
      });
    });
  }
  if (videos.clips) {
    selectOptions.push({
      name: t('Clips'),
      value: 'clips'
    });
  }

  return (
    <ContainerMain childrenMarginBottom="base">
      <SectionHeader title={t('explore videos')} />
      <SectionSelect
        value={season}
        options={[{ name: t('All Seasons'), value: '' }, ...selectOptions]}
        onChange={handleChange}
      />
      <CardGridLeftRightWithAdLegacy cardItems={cardItems} content={content} />
    </ContainerMain>
  );
};

const videoShape = {
  aspect: PropTypes.string,
  guid: PropTypes.string,
  image_url: PropTypes.string,
  pg_rating: PropTypes.string,
  promo_summary: PropTypes.string,
  search_index: PropTypes.string,
  size: PropTypes.string,
  tag: PropTypes.string,
  tagUrl: PropTypes.string,
  target_id: PropTypes.string,
  title: PropTypes.string,
  totalSecs: PropTypes.string,
  url: PropTypes.string
};

ShowVideos.propTypes = {
  videos: PropTypes.shape({
    clips: PropTypes.arrayOf(PropTypes.shape(videoShape)),
    seasons: PropTypes.objectOf(
      PropTypes.shape({
        clips: PropTypes.arrayOf(PropTypes.shape(videoShape)),
        episodes: PropTypes.arrayOf(PropTypes.shape(videoShape))
      })
    )
  }).isRequired,
  content: PropTypes.objectOf(PropTypes.any).isRequired
};
